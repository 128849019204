.newsletter-form {

    .hs-button[type="submit"] {
        font-family: 'FontAwesome';
    }
    
    &.hs-form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    &.hs-form .hs-email {
        margin: 0;
        .hs-input.invalid.error {
            border-right: 1px solid;
            box-shadow: 0px 0px 5px $danger;
        }
    }
    
    &.hs-form .hs-email .hs-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }
    
    &.hs-form .hs-email .hs-input:focus {
        border-color: #ced4da;
        box-shadow: none;
    }
    
    &.hs-form .hs-email > label {
        display: none;
    }
    
    &.hs-form .hs-submit .hs-button {
        border-top-left-radius: 0;
        border-left: none;
        border-bottom-left-radius: 0;
        border-color: #ced4da;
    }

    .hs-error-msgs {
        label>a {
            color: $white;
        }
    }
}

/**
 * Any hack files must go beneath the regular styles so their specificity takes precedence
 * over the default styles.
 */