$footer-links: #7e8bb8;

footer {
    background: $offWhite;
    font-size: 0.9em;

    a {
        color: $footer-links;

        :hover {
            color: darken($brandLightBlue, 15%);
            text-decoration: none;
        }
    }
    
    .address-container {
        font-size: 0.8em;
    }

    address {
        margin-bottom: 0;
    }

    h6 {
        margin-bottom: 0;
        font-size: 0.9rem;
    }

    a.social-icon {
        font-size: 3em;
        color: $gray;
        margin: 0 0.25em;
        i {
            transition: color 0.15s ease-in-out,
                        background-color 0.15s ease-in-out,
                        border-color 0.15s ease-in-out,
                        box-shadow 0.15s ease-in-out;
        }
        &:hover {
            i {
                color: $brandLightGreen;
            }
        }
    }

    .hbspt-form {
        margin:auto;
        width:14rem;
    }

    #newsletter-form {
        .input-group {
            border: 1px solid $gray;
            border-radius: 0.25rem;
        }

        button {
            background-color: $brandDarkGreen;
            color: $white;
            border: none;
        }
    }

    .footer-news {
        font-size: 0.9em;
        width: 100%;
        margin: 0 auto 0.5em;
        @media (min-width: 992px) {
            width: 65%;
        }
    }


    .footer-news-title {
        font-weight: 500;
        font-size: 1em;
    }
}